<template>
  <svg width="21px" height="21px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="m 8 1 c -1.65625 0 -3 1.34375 -3 3 s 1.34375 3 3 3 s 3 -1.34375 3 -3 s -1.34375 -3 -3 -3 z m -1.5 7 c -2.492188 0 -4.5 2.007812 -4.5 4.5 v 0.5 c 0 1.109375 0.890625 2 2 2 h 6 v -1 h -3 v -4 h 3 v -1.972656 c -0.164062 -0.019532 -0.332031 -0.027344 -0.5 -0.027344 z m 4.5 0 v 3 h -3 v 2 h 3 v 3 h 2 v -3 h 3 v -2 h -3 v -3 z m 0 0" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: 'ContactNew',
  props: {
    fill: {
      type: String,
      required: true
    }
  }
}
</script>
