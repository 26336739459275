/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
==========================================================================================*/

import { isProduction } from "../../../../globalHelpers/environmentHelpers";

let menuItem = [
  {
    url: "/org-owner/home",
    name: "Insights",
    icon: "HomeIcon",
    slug: "home",
    i18n: "Home"
  },
  {
    url: "/org-owner/nurse-dashboard",
    name: "nurse-dashboard",
    icon: "HomeIcon",
    slug: "home",
    i18n: "Home"
  },
   {
      url: "/org-owner/notice-board",
      name: "Dashboard",
      icon: "MonitorIcon",
      slug: "dashboard",
      i18n: "Notice Board"
    },
  ...['NZ', 'AU'].includes(process.env.VUE_APP_REGION) ? [
    {
      url: "/org-owner/shop",
      name: "Shop",
      icon: "ShoppingBagIcon",
      slug: "shop",
      i18n: "Shop"
    },
    {
      url: "/org-owner/orders",
      name: "My Orders",
      slug: "orders",
      i18n: "My Orders",
      icon: "TruckIcon"
    },
    {
      url: "/org-owner/patients",
      name: "Patients",
      slug: "pages-register-nurse",
      i18n: "Patients",
      icon: "FolderPlusIcon"
    },
    {
      url: "/org-owner/invoices",
      name: "Invoice Management",
      slug: "invoice",
      i18n: "Invoices",
      icon: "DollarSignIcon"
    },
    // {
    //   url: "/org-owner/clinic-dashboard",
    //   name: "Insights",
    //   icon: "BarChart2Icon",
    //   slug: "insights",
    //   i18n: "Insights",
    // },
    {
      url: '/org-owner/pos',
      name: "POS",
      slug: "pos",
      i18n: "POS",
      icon: "DollarSignIcon",
    },
    {
      url: "/org-owner/referral",
      name: "Refer Someone to Fresh",
      slug: "referral",
      i18n: "Refer Someone to Fresh",
      icon: "UserPlusIcon"
    },
    {
      header: "Resource Hub",
      i18n: "Resource Hub",
      items: [
        {
          url: "/org-owner/resources",
          name: "Resources",
          icon: "CodepenIcon",
          slug: "resources",
          i18n: "Resources"
        },
        ...['NZ', 'AU'].includes(process.env.VUE_APP_REGION) ? [
          {
            url: "/org-owner/financial-assistance",
            name: "Financial Assistance",
            icon: "GridIcon",
            slug: "financial-assistance",
            i18n: "Financial Assistance",
          },
        ] : []
      ]
    },
    {
      header: "Training Hub",
      i18n: "Training Hub",
      items: [
        {
          url: "/org-owner/events",
          name: ['AU'].includes(process.env.VUE_APP_REGION) ? "Webinars & Training": "Events", // Applies to AU only for now
          icon: "CalendarIcon",
          slug: "events",
          i18n: ['AU'].includes(process.env.VUE_APP_REGION) ? "Webinars & Training": "Events" // Applies to AU only for now
        },
        // Applies to AU only
        ['AU'].includes(process.env.VUE_APP_REGION) && {
          url: "/org-owner/supplier-training",
          name: 'Supplier Training',
          icon: "CalendarIcon",
          slug: "supplier-training",
          i18n: 'Supplier Training'
        }
      ]
    },
    {
      header: "Compliance",
      i18n: "Compliance",
      items: [
        {
          url: "/org-owner/drugbook",
          name: "Drugbook",
          icon: "BookIcon",
          slug: "drugbook",
          i18n: "Drugbook",
        },
        {
          url: "/org-owner/inventory",
          name: "Inventory",
          featherIcon: false,
          icon: "kitchen",
          slug: "inventory",
          i18n: "Inventory",
        },
        {
          url: "/org-owner/compliance",
          name: "Compliance",
          icon: "GridIcon",
          slug: "compliance",
          i18n: "Compliance Checks",
        },
      ]
    },
  ] : [
    {
      url: "/org-owner/patients",
      name: "Patients",
      slug: "pages-register-nurse",
      i18n: "Patients",
      icon: "FolderPlusIcon"
    },
    {
      url: '/org-owner/pos',
      name: "POS",
      slug: "pos",
      i18n: "POS",
      icon: "DollarSignIcon",
    },
    {
      url: "/org-owner/invoices",
      name: "Invoice Management",
      slug: "invoice",
      i18n: "Invoices",
      icon: "DollarSignIcon"
    },
    {
      url: "/org-owner/events",
      name: "Events",
      icon: "CalendarIcon",
      slug: "events",
      i18n: "Events"
    },
    {
      url: "/org-owner/referral",
      name: "Refer Someone to Fresh",
      slug: "referral",
      i18n: "Refer Someone to Fresh",
      icon: "UserPlusIcon"
    },
    {
      url: "/org-owner/resources",
      name: "Resources",
      icon: "CodepenIcon",
      slug: "resources",
      i18n: "Resources"
    },
    {
      url: "/org-owner/financial-assistance",
      name: "Financial Assistance",
      icon: "GridIcon",
      slug: "financial-assistance",
      i18n: "Financial Assistance",
    },
    {
      url: "/org-owner/compliance",
      name: "Compliance",
      icon: "GridIcon",
      slug: "compliance",
      i18n: "Compliance Checks",
    },
    {
      url: "/org-owner/drugbook",
      name: "Drugbook",
      icon: "BookIcon",
      slug: "drugbook",
      i18n: "Drugbook",
    },
    // {
    //   url: "/org-owner/clinic-dashboard",
    //   name: "Insights",
    //   icon: "BarChart2Icon",
    //   slug: "insights",
    //   i18n: "Insights",
    // },
    {
      url: "/org-owner/inventory",
      name: "Inventory",
      featherIcon: false,
      icon: "kitchen",
      slug: "inventory",
      i18n: "Inventory",
    },
    {
      url: "/org-owner/consent-forms",
      name: "Consent Forms",
      icon: "ActivityIcon",
      slug: "consent-forms",
      i18n: "Consent Forms",
    },
    {
      url: "/org-owner/shop",
      name: "Shop",
      icon: "ShoppingBagIcon",
      slug: "shop",
      i18n: "Shop"
    },
    {
      url: "/org-owner/orders",
      name: "My Orders",
      slug: "orders",
      i18n: "My Orders",
      icon: "TruckIcon"
    }
  ],
  {
    header: "Settings",
    icon: "SettingsIcon",
    i18n: "Settings",
    items: [
      {
        url: "/org-owner/clinics",
        name: "org-ownerClinics",
        slug: "org-owner-clinics-management",
        i18n: "Clinics",
        icon: "HomeIcon"
      },
      {
        url: "/org-owner/staff",
        name: "Staff",
        icon: "UsersIcon",
        slug: "staff",
        i18n: "Staff"
      },
      ...['NZ', 'AU'].includes(process.env.VUE_APP_REGION) ? [
        {
          url: "/org-owner/consent-forms",
          name: "Consent Forms",
          icon: "ActivityIcon",
          slug: "consent-forms",
          i18n: "Consent Forms",
          permission: 'CustomConsentForm',
        },
        {
          url: "/org-owner/aftercare",
          name: "Aftercare Docs",
          slug: "aftercare-docs",
          icon: "FileTextIcon",
          i18n: "Aftercare Docs",
          permission: 'CustomAftercareDocument',
        },
      ] : [],
      {
        url: "/org-owner/settings/products-list",
        name: "org-owner Products List",
        slug: "org-owner-products-list",
        icon: "CheckSquareIcon",
        i18n: "Products List"
      },
      {
        url: "/org-owner/settings/treatment-areas",
        name: "org-owner Treatment Areas",
        slug: "org-owner-treatment-areas",
        icon: "ShuffleIcon",
        i18n: "Treatment Areas"
      }
    ]
  }
];

if (process.env.VUE_APP_REGION !== "AU") {
  menuItem = menuItem.filter((val) => val.slug !== 'financial-assistance');
}

/**
 * PRODUCTION FLAG
 * This will be removed later from POS updates
 * */
// if( isProduction ){
//   menuItem = menuItem.filter((val) => val.slug !== 'pos');
// }

export default menuItem;
